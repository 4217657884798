/*
 *
 *  remotes
 *
 */

import { decryption, encryption } from 'cipher/cipher';
import api from '../../helpers/api';
import { convertObjectToParams } from 'helpers/tools';

/**
 * @param {string} name 
 * @param {object} remotesBlock 
 */
export default function (name, remotesBlock = {}) {

  let constantName = name;

  async function loadRecords(params) {
    let response = await api.get(`/rest/${constantName === 'comments' ? `post/comments/${params.id}` : constantName}?${convertObjectToParams(params)}`).then((response) => response.data).catch((error) => Promise.reject(error));
    let decryptData = await decryption(response);
    return decryptData;
  }

  /**
   * 
   * @param {string} metaData 
   * @returns 
   */
  function loadRecordsMetaData(metaData) {
    return api.get(`/rest/${metaData}`).then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData;
      // response && response.data
    }).catch((error) => Promise.reject(error));
  }

  /**
   * 
   * @param {integer} id 
   * @returns 
   */
  function loadRecord(id) {
    return api.get(`/${constantName}/${id}`).then((response) => response.data).catch((error) => Promise.reject(error));
  }

  /**
   * 
   * @param {object} record 
   * @returns 
   */
  async function createRecord(record) {
    let decryptData = await encryption(record);
    record = { data: decryptData };
    return api.post(name === 'comments' ? `/rest/${constantName}` : `/${constantName}`, record).then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData;
      // response.data
    }).catch((error) => Promise.reject(error));
  }

  /**
   * 
   * @param {object} record 
   * @returns 
   */
  async function updateRecord(record) {
    let encryptData = await encryption(record);
    return api.put(name === 'comments' ? `/rest/${constantName}/${record.id}` : `/${constantName}/${record.id}`, { data: encryptData }).then(async (response) => {
      let decryptData = await decryption(response.data);
      return decryptData;
    }).catch((error) => Promise.reject(error));
  }

  /**
   * 
   * @param {object} records
   * @returns 
   */
  function updateRecords(records) {
    return api.put(`rest/${constantName}/bulk-update`, records).then((response) => response.data).catch((error) => Promise.reject(error));
  }

  /**
   * 
   * @param {object} id
   * @returns 
   */
  // function setActiveRecord(record) {
  //   return api.post(`${constantName}/set-status`, record).then((response) => response.data).catch((error) => Promise.reject(error));
  // }

  // for active status for entire application
  async function setActiveRecord(record) {
    try {
      let encryptData = await encryption(record);
      const response = await api.post(`${constantName}/set-status`, { data: encryptData });
      let decryptData = await decryption(response.data);
      return decryptData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 
   * @param {string} id 
   * @returns 
   */
  function deleteRecord(id) {
    return api.delete(name === "comments" ? `/rest/${constantName}/${id}` : `/${constantName}/${id}`).catch((error) => Promise.reject(error));
  }

  return {
    loadRecords,
    loadRecordsMetaData,
    loadRecord,
    createRecord,
    updateRecord,
    updateRecords,
    setActiveRecord,
    deleteRecord,
    ...remotesBlock
  }


}