import PropTypes from 'prop-types'
import React, { Component } from "react"

// Import menuDropdown
import NotificationModal from "../NotificationModal"
import ProfileMenu from "../ProfileMenu"

import logo from "../../assets/AppLogo/Logo Icon color@3x.png"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"
import withRouter from 'helpers/withRouter'
import schema from 'schema'
import { Link } from 'react-router-dom'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      position: 'right',
      unit_code: false,
      show_search_box: false,
      openDrawer: false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.setState({ openDrawer: !this.state.openDrawer })
    this.props.toggleMenuCallback()
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  render() {
    const { location = {} } = this.props;
    const { pathname } = location;
    const pageData = schema && Array.isArray(schema) && schema.length > 0 && schema.find(_ => pathname.indexOf(_.name) > -1);

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">

            <div className="d-flex align-items-center">
              <button
                type="button"
                aria-label="vertical menu"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-20 header-item drawer_toggle_item"
                id="vertical-menu-btn"
              >
                <i className={this.state.openDrawer ? "fa fa-fw fa-angle-left" : "fa fa-fw fa-angle-right"}></i>
              </button>


              <div className="navbar-brand-box d-block d-lg-none" style={{ background: 'transparent' }}>
                <Link to="/" aria-label="logo" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo} alt="logo" style={{ width: '65px', marginLeft: '-12px', marginTop: '-1px', height: '50px' }} />
                  </span>
                </Link>
              </div>

              <h4 className="d-none d-lg-block ms-3 mb-0" style={{ }}>{pathname === '/' ? 'Dashboard' : pageData && pageData.title}</h4>

            </div>
            <div className="d-flex align-items-center">
              <NotificationModal />
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(Header))


Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}