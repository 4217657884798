/*
 *
 *  session sagas
 *
 */

import { call, take, put, all } from 'redux-saga/effects';
import { setAuthToken } from '../../helpers/api';
import { getContentType } from '../../helpers/tools';
import { uploadFile, uploadS3File } from '../upload/remotes';

import store2 from 'store2';
import { LOG_IN, LOG_OUT, VERIFY_SESSION, FORGOT_PASSWORD, CHANGE_PASSWORD, VERIFY_OTP, NOTIFICATION, UPLOAD_FILES } from './constants';

import {
  verifySession as verifySessionAction,
  verifySessionSuccess,
  verifySessionError,
  logInSuccess,
  logInError,
  logOutSuccess,
  logOutError,
  forgotPasswordError,
  forgotPasswordSuccess,
  changePasswordError,
  changePasswordSuccess,
  verifyOtpError,
  verifyOtpSuccess,
  notificationSuccess,
  notificationError,
  uploadFiles
} from './actions';

import { logIn, verifySession, forgotPassword, changePassword, verifyOtp, notification } from './remotes';

import _ from 'lodash';

export function* verifyInitialSessionSaga() {
  const secret = store2.get('secret');
  const accType = store2.get('accType');

  try {
    setAuthToken(secret);
    store2.set('secret', secret);
    const user = yield call(verifySession, accType);
    yield put(verifySessionSuccess(user));
  } catch (error) {
    store2.remove('secret');
    yield put(verifySessionError("Error"));
  }
}

export function* verifySessionSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { secret, accType } = yield take(VERIFY_SESSION);
    if (secret && accType) {
      try {
        setAuthToken(secret);
        const user = yield call(verifySession, accType);
        yield put(verifySessionSuccess(user));
      } catch (error) {
        yield put(verifySessionError("Error"))
      }
    } else {
      try {
        yield put(logOutSuccess());
      } catch (error) {
        yield put(logOutError(error));
      } finally {
        store2.remove('secret');
      }
    }
  }
}

export function* loginSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record, setSubmitting, redirect } = yield take(LOG_IN);

    try {
      yield call(setSubmitting, true)
      const result = yield call(logIn, record);
      if (result && result.authToken) {
        setAuthToken(result.authToken);
        store2.set('secret', result.authToken);
        yield put(logInSuccess(result.authToken || {}));
        yield put(verifySessionAction(result.authToken, record.type));
      } if (result && result.sample_url && redirect) {
        yield call(redirect, result && result.sample_url);
      }
    } catch (error) {
      let Err;
      if (error?.response?.status == 402) {
        Err = 'Your account is currently inactive. Please reach out to our support team for assistance.';
      } else {
        Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || "Please provide the correct Details"
      }
      yield put(logInError(Err))
    } finally {
      yield call(setSubmitting, false)
    }
  }
}

export function* logOutSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const watcher = yield take(LOG_OUT);

    if (watcher) {
      const { redirect } = watcher;
      try {
        yield put(logOutSuccess());
        if (redirect)
          yield call(redirect)
      } catch (error) {
        yield put(logOutError(error));
      } finally {
        store2.remove('secret');
      }
    }
  }
}


export function* forgotPasswordSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record, setSubmitting, redirect } = yield take(FORGOT_PASSWORD);

    try {
      yield call(setSubmitting, true);
      const result = yield call(forgotPassword, record);
      yield put(forgotPasswordSuccess(result.message));
      if (redirect)
        yield call(redirect)
    } catch (error) {
      const Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || "Please provide the correct Details"
      yield put(forgotPasswordError(Err));
    } finally {
      yield call(setSubmitting, false);
    }
  }
}


export function* changePasswordSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { record = {}, setSubmitting, redirect } = yield take(CHANGE_PASSWORD);

    try {
      yield call(setSubmitting, true);
      const result = yield call(changePassword, record);
      yield put(changePasswordSuccess(result.message));
      if (redirect)
        yield call(redirect)
    } catch (error) {
      const Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || "Please provide the correct Details"
      yield put(changePasswordError(Err))
    } finally {
      yield call(setSubmitting, false);
    }
  }
}


export function* verifyOtpSaga() {
  while (true) {
    const { record, setSubmitting } = yield take(VERIFY_OTP);

    try {
      yield call(setSubmitting, true);
      const result = yield call(verifyOtp, record);
      if (result) {
        setAuthToken(result.authToken);
        store2.set('secret', result.authToken);
        yield put(verifyOtpSuccess(result.authToken || {}));
        yield put(verifySessionAction(result.authToken, record.type));
      }
    } catch (error) {
      const Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || "Invalid Code."
      yield put(verifyOtpError(Err));
    } finally {
      yield call(setSubmitting, false);
    }
  }
}

export function* notificationSaga() {
  while (true) {
    yield take(NOTIFICATION);


    try {
      const result = yield call(notification);
      if (result) {
        yield put(notificationSuccess(result));
      } else {
        yield put(notificationError("Unable to get notification data."));
      }
    } catch (error) {
      const Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || "Unable to get notification data."
      yield put(notificationError(Err));
    }
  }
}

export function* uploadFilesSaga() {
  while (true) {
    const { files, uploadUrl, setUpload, setValues, setError } = yield take(UPLOAD_FILES);
    setError(false); //Changing the error state to initial

    if (files && files.length > 0) {
      try {
        setUpload(true);

        const uploadFiles = yield call(uploadFile, files);
        if (uploadFiles) {
          yield call(setValues, uploadFiles.imageUrl.map(_ => global.config.API_URL + _).join(','))
        }
        // const uploadedFiles = yield all(
        //   files.map(file => call(uploadFile, 'USR' + '.' + file.name.split('?')[0].split('.').pop(), getContentType(file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-")), uploadUrl, file))
        // )
        // if (uploadedFiles) {
        //   yield call(setValues, uploadedFiles.map((item, index) => {
        //     return global.config.API_URL + item
        //   }).join(','))
        // }
        // const uploadedFiles = yield all(files.map(file => call(uploadFile, file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-"), getContentType(file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-")), uploadUrl, file)));
        // if (uploadedFiles) {
        //   const uploadedS3Files = yield all(uploadedFiles.map(({ uploadURL, public_url, file }) => call(uploadS3File, file, getContentType(file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-")), uploadURL, public_url)))
        //   yield call(setValues, uploadedS3Files.map(_ => _.public_url).join(','))
        // }
      } catch (error) {
        const Err = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.data && error.response.data.error) || 'Upload Failed'
        setError(Err)
      } finally {
        setUpload(false)
      }
    } else {
      setError('Upload file format not supported.')
    }
  }
}



export default function* rootSaga() {
  yield all([
    verifyInitialSessionSaga(),
    verifySessionSaga(),
    loginSaga(),
    logOutSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    verifyOtpSaga(),
    notificationSaga(),
    uploadFilesSaga()
  ]);
}